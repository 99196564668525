import React, { useEffect, useState } from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/layout.css"

function IndexPage() {
  const [email1, setEmail1] = useState("");
  const [email2, setEmail2] = useState("");
  const [email3, setEmail3] = useState("");
  const [email4, setEmail4] = useState("");

  useEffect(() => {
    setEmail1("admin");
    setEmail2("@")
    setEmail3("scgough")
    setEmail4(".co.uk")
  }, []);

  return (
    <Layout>
      <SEO title="SCGough Ltd. Privacy Policy" description="The privacy policy for SCGough Ltd." />
      <OutboundLink href="/">{`👈 Back to Home`}</OutboundLink>
      <br /><br />
      <h1 style={{marginBottom:30}}>Privacy Policy</h1>
      <p>The protection of your privacy when it comes to processing any personal data we may have about you is important to us. SCGough Ltd. (referred to as 'we' or 'us' herinafter) use your data in compliance with applicable data protection legislation. </p>
      
      <h4>Our Contact Details:</h4>
      <p>SCGough Ltd.
        <br />Stoneygate House, 2 Greenfield Road, Holmfirth, West Yorkshire, HD9 2JT, United Kingdom
        <br />Email: <OutboundLink 
        href={`mailto:${email1}${email2}${email3}${email4}`} style={{backgroundColor: 'transparent', color: 'hsla(0, 0%, 0%, 0.8)', fontWeight: 'normal'}}>{`${email1}${email2}${email3}${email4}`}</OutboundLink>
      </p>
      
      <h4>The type of personal information we collect:</h4>
      <p>If you contact us by email, we will have access to personally identifiers such as your name and email address.</p>
      <p>We also use cookies to store functional and usage data when you visit this website. For example, we collect information about the pages you visit, the type of device you use, the browser you use, the operating system you use, your IP address and general geographic location. </p>
      
      <h4>How we get the personal information and why we have it</h4>
      <p>Most of the personal information we process is provided to us directly by you for one of the following reasons: </p>
      <ul>
        <li>You visit this website</li>
        <li>You contact us via email directly</li>
      </ul>
      <p>We use the information that you have given us in order to provide you with services we offer (i.e. software development) or to communicate with you directly, for example via email. Examples of how we use your personal information are listed below:</p>
      <ul>
        <li>Customer Support - respond to enquiries, diagnose & repair issues</li>
        <li>Communication - we use your data to personalise our communication with you via email, phone or other means of electronic communication.</li>
      </ul>
      <p>We may share this information with third party vendors to carry out any services we may perform on your behalf. We will only share your personal information when absolutely necessary and with your prior approval, for example via email communication.</p>
      <p>Under the UK General Data Protection Regulation (UK GDPR), the lawful bases we rely on for processing this information are:</p>
      <ul>
        <li>We have a contractual obligation.</li>
        <li>We have a legal obligation.</li>
        <li>We have a legitimate interest.</li>
      </ul>
      <h4>How we store your personal information </h4>
      <p>Your information is securely stored. We will keep contact information such as name, email and address for as long as we deem necessary to provide you with the services we offer and to conduct our business. Personal information we retain will be subject to this privacy policy.</p>
      <strong>Cookies:</strong>
      <p>When you visit this website we store information on your computer in the form of 'cookies'. Cookies are small files sent to your browser but don't contain any sensitive/personally identifiable data.</p>
      <p>These cookies are used on this website:</p>
      <table> 
        <thead>
          <tr>
            <th>Name</th>
            <th>Purpose</th>
            <th>Expiry</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={3}><strong>Google Analytics</strong> - used to analyse the website usage and compile reports on website activity.</td>
          </tr>
          <tr>
            <td>_ga</td>
            <td>Used to identify unique users</td>
            <td>2 years</td>
          </tr>
          <tr>
            <td>_gat</td>
            <td>Used to throttle request rate</td>
            <td>1 minute</td>
          </tr>
          <tr>
            <td>_gid</td>
            <td>Used to identify you, the user</td>
            <td>24 hours</td>
          </tr>
        </tbody>
      </table>
      <p>You can learn more about Google Analytics and how they use your data by <OutboundLink target="_blank" href="https://support.google.com/analytics/answer/6004245" style={{backgroundColor: 'transparent', color: 'hsla(0, 0%, 0%, 0.8)', fontWeight: 'normal', padding: 0, textDecoration: 'underline'}}>visiting this link</OutboundLink>.</p>
      <h4>Your data protection rights</h4>
      <p>Under data protection law, you have rights including:</p>
      <ul>
        <li>Your right of access - You have the right to ask us for copies of your personal information.</li>
        <li>Your right to rectification - You have the right to ask us to rectify personal information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete. </li>
        <li>Your right to erasure - You have the right to ask us to erase your personal information in certain circumstances.</li>
      </ul>
      <p>You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond to you.</p>
      <p>Please contact us at <OutboundLink 
        href={`mailto:${email1}${email2}${email3}${email4}`} style={{backgroundColor: 'transparent', color: 'hsla(0, 0%, 0%, 0.8)', fontWeight: 'normal', padding: 0}}>{`${email1}${email2}${email3}${email4}`}</OutboundLink> if you wish to make a request.</p>
    
      <h4>How to complain</h4>
      <p>If you have any concerns about our use of your personal information, you can make a complaint to us at <OutboundLink 
        href={`mailto:${email1}${email2}${email3}${email4}`} style={{backgroundColor: 'transparent', color: 'hsla(0, 0%, 0%, 0.8)', fontWeight: 'normal', padding: 0}}>{`${email1}${email2}${email3}${email4}`}</OutboundLink>.</p>
      <p>You can also complain to the ICO if you are unhappy with how we have used your data.</p>
      <p>The ICO’s address:</p>
      <p> Information Commissioner’s Office
        <br />Wycliffe House
        <br />Water Lane
        <br />Wilmslow
        <br />Cheshire
        <br />SK9 5AF</p>

      <p>Helpline number: 0303 123 1113
      <br />ICO website: https://www.ico.org.uk</p>

    </Layout>
  )
}

export default IndexPage
